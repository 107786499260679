import { ProductCategory } from './index';
import { ProductCategoriesDto } from './product-categories.dto';

export class ProductCategories {
    categories: ProductCategory[];
    others: ProductCategory[];

    constructor(dto: ProductCategoriesDto) {
        const categories = dto.categories.map((item) => new ProductCategory(item));
        const groupped = this.groupByParentCategory(categories);

        this.categories = this.getCategoriesTree('', groupped);
        this.others = dto.others.map((item) => new ProductCategory(item));
    }

    private groupByParentCategory(categories: ProductCategory[]) {
        return categories.reduce(
            (acc, item) => {
                if (!acc[item.parentCategory]) {
                    acc[item.parentCategory] = [];
                }

                acc[item.parentCategory].push(item);

                return acc;
            },
            <Record<string, ProductCategory[]>>{},
        );
    }

    private getCategoriesTree(groupKey: string, items: Record<string, ProductCategory[]>) {
        const categoriesInGroup = items[groupKey] ?? [];

        return categoriesInGroup
            .map((item): ProductCategory => {
                const tree = this.getCategoriesTree(item.code, items);

                return { ...item, children: tree };
            })
            .sort((a, b) => a.presentationOrder - b.presentationOrder);
    }
}
