import { TreeNode } from '@app/shared/forms/form-tree-dropdown';
import { ProductCategoryDto } from './index';

export class ProductCategory implements TreeNode {
    code: string;
    parentCategory: string;
    description: string;
    id: string;
    matched: boolean;
    hasMatchedChildren: boolean;
    presentationOrder: number;
    children: ProductCategory[];

    constructor(productCategoryDto: ProductCategoryDto) {
        this.code = productCategoryDto.code;
        this.parentCategory = productCategoryDto.parent_category;
        this.description = productCategoryDto.description;
        this.id = productCategoryDto.id;
        this.presentationOrder = productCategoryDto.presentation_order;
        this.matched = true;
        this.hasMatchedChildren = true;
        this.children = [];
    }
}
